/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Reddit event listeners
 *
 * Basic info: https://redditinc.force.com/helpcenter/s/article/Install-the-Reddit-Pixel-on-your-website
 * Advanced matching: https://redditinc.force.com/helpcenter/s/article/Reddit-Pixel-Advanced-Matching
 * Event metadata: https://redditinc.force.com/helpcenter/s/article/Reddit-Pixel-Event-Metadata
 * */

// Pixel load event listener
jQuery(document).on("pmwLoadPixels", () => {
	if (wpmDataLayer?.pixels?.reddit?.advertiser_id && !wpmDataLayer?.pixels?.reddit?.loaded) {
		if (wpm.consent.canPixelBeFired("marketing", "Reddit")) wpm.loadRedditPixel()
	}
})

// AddToCart event
jQuery(document).on("wpmAddToCart", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.reddit?.loaded) return

		let data = {
			currency : product.currency,
			itemCount: product.quantity,
			value    : product.price * product.quantity,
		}

		rdt("track", "AddToCart", data)
		wpm.consoleLog("Reddit Pixel: AddToCart event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// ViewContent event
jQuery(document).on("wpmViewItem", (event, product = null) => {

	try {
		if (!wpmDataLayer?.pixels?.reddit?.loaded) return

		rdt("track", "ViewContent")
		wpm.consoleLog("Reddit Pixel: ViewContent event sent")

	} catch (e) {
		console.error(e)
	}
})

// view search event
jQuery(document).on("pmwEvent:Search", () => {

	try {
		if (!wpmDataLayer?.pixels?.reddit?.loaded) return

		rdt("track", "Search")
		wpm.consoleLog("Reddit Pixel: search event sent")

	} catch (e) {
		console.error(e)
	}
})

jQuery(document).on("wpmAddToWishlist", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.reddit?.loaded) return

		let data = {
			currency : product.currency,
			itemCount: product.quantity,
			value    : product.price * product.quantity,
		}

		rdt("track", "AddToWishlist", data)
		wpm.consoleLog("Reddit Pixel: AddToWishlist event sent", data)

	} catch (error) {
		console.error(error)
	}
})

// view order received page event
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.reddit?.loaded) return

		let data = {
			transactionId: wpmDataLayer.order.number,
			currency     : wpmDataLayer.order.currency,
			value        : wpmDataLayer.order.value.marketing,
		}

		// Get order item count
		// Go through each order item and sum up the quantity
		if (wpmDataLayer.order.items) {
			let itemCount = 0
			wpmDataLayer.order.items.forEach(item => {
				itemCount += parseInt(item.quantity)
			})

			data.itemCount = itemCount
		}

		rdt("track", "Purchase", data)
		wpm.consoleLog("Reddit Pixel: Purchase event sent", data)

	} catch (e) {
		console.error(e)
	}
})
