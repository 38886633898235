/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Snapchat Ads functions
 * */

(function (wpm, $, undefined) {

	wpm.snapchatGetUserInfo = () => {

		if (!wpmDataLayer?.pixels?.snapchat?.advanced_matching) {
			return {}
		}

		let userInfo = {}

		if (wpmDataLayer?.user?.email?.sha256) {
			userInfo.user_hashed_email = wpmDataLayer.user.email.sha256
		}

		if (wpmDataLayer?.user?.phone?.sha256?.snapchat) {
			userInfo.user_hashed_phone_number = wpmDataLayer.user.phone.sha256.snapchat
		}

		return userInfo
	}

	wpm.loadSnapchatPixel = () => {

		try {
			wpmDataLayer.pixels.snapchat.loaded = true;

			// @formatter:off
			(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
			{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
				a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
				r.src=n;var u=t.getElementsByTagName(s)[0];
				u.parentNode.insertBefore(r,u);})(window,document,
				'https://sc-static.net/scevent.min.js');

			snaptr("init", wpmDataLayer.pixels.snapchat.pixel_id, wpm.snapchatGetUserInfo());

			snaptr("track", "PAGE_VIEW")
			// @formatter:on


		} catch (e) {
			console.error(e)
		}
	}

	wpm.getSnapchatOrderItemIds = () => {
		let contentIds = []

		Object.values(wpmDataLayer.order.items).forEach(item => {

			if (wpmDataLayer?.shop?.variations_output && 0 !== item.variation_id) {
				contentIds.push(String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.snapchat.dynamic_remarketing.id_type]))
			} else {
				contentIds.push(String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.snapchat.dynamic_remarketing.id_type]))
			}
		})

		return contentIds
	}

}(window.wpm = window.wpm || {}, jQuery));
