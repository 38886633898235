// TODO implement AddPaymentInfo event
// TODO check if more values can be passed to product and cart pages

// https://business.twitter.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html

/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Twitter Ads event listeners
 * */

// Pixel load event listener
jQuery(document).on("pmwLoadPixels", () => {

	if (wpmDataLayer?.pixels?.twitter?.pixel_id && !wpmDataLayer?.pixels?.twitter?.loaded) {
		if (wpm.consent.canPixelBeFired("marketing", "Twitter")) wpm.loadTwitterPixel()
	}
})

// add-to-cart event
jQuery(document).on("wpmAddToCart", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.twitter?.loaded) return

		if (wpmDataLayer?.pixels?.twitter?.event_ids?.add_to_cart) {

			const data = {
				value   : product.quantity * product.price,
				currency: wpmDataLayer.shop.currency,
				contents: [{
					content_type : "product",
					content_id   : wpmDataLayer.products[product.id].dyn_r_ids[wpmDataLayer.pixels.twitter.dynamic_remarketing.id_type],
					content_name : product.name,
					content_price: product.price,
					num_items    : product.quantity,
					// content_group_id: null,
				}],
			}

			twq("event", wpmDataLayer.pixels.twitter.event_ids.add_to_cart, data)
			wpm.consoleLog("Twitter Pixel: add_to_cart event sent", data)
		} else {
			// Legacy event
			twq("track", "AddToCart")
			wpm.consoleLog("Twitter Pixel: add_to_cart event sent")
		}
	} catch (e) {
		console.error(e)
	}
})

// view product event
jQuery(document).on("wpmViewItem", (event, product = null) => {

	try {
		if (!wpmDataLayer?.pixels?.twitter?.loaded) return

		let productData = {}

		if (product) {
			productData = {
				value   : product.quantity * product.price,
				currency: wpmDataLayer.shop.currency,
				contents: [{
					content_type : "product",
					content_id   : wpmDataLayer.products[product.id].dyn_r_ids[wpmDataLayer.pixels.twitter.dynamic_remarketing.id_type],
					content_name : product.name,
					content_price: product.price,
					num_items    : product.quantity,
					// content_group_id: null,
				}],
			}
		}

		if (wpmDataLayer?.pixels?.twitter?.event_ids?.view_content) {
			twq("event", wpmDataLayer.pixels.twitter.event_ids.view_content, productData)
			wpm.consoleLog("Twitter Pixel: view_content event sent", productData)
		} else {
			// Legacy event
			twq("track", "ViewContent")
			wpm.consoleLog("Twitter Pixel: view_content event sent")
		}

	} catch (e) {
		console.error(e)
	}
})

// // view category event
// jQuery(document).on('wpmCategory', function () {
//
// 	if (!wpmDataLayer?.pixels?.twitter?.loaded) return;
//
// 	// twq('track', 'AddToWishlist');
// });

// add-to-wishlist event
jQuery(document).on("wpmAddToWishlist", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.twitter?.loaded) return

		let productData = {}

		if (product) {
			productData = {
				value   : product.quantity * product.price,
				currency: wpmDataLayer.shop.currency,
				contents: [{
					content_type : "product",
					content_id   : wpmDataLayer.products[product.id].dyn_r_ids[wpmDataLayer.pixels.twitter.dynamic_remarketing.id_type],
					content_name : product.name,
					content_price: product.price,
					num_items    : product.quantity,
					// content_group_id: null,
				}],
			}
		}

		if (wpmDataLayer?.pixels?.twitter?.event_ids?.add_to_wishlist) {
			twq("event", wpmDataLayer.pixels.twitter.event_ids.add_to_wishlist, productData)
			wpm.consoleLog("Twitter Pixel: add_to_wishlist event sent", productData)
		} else {
			// Legacy event
			twq("track", "AddToWishlist")
			wpm.consoleLog("Twitter Pixel: add_to_wishlist event sent")
		}

	} catch (e) {
		console.error(e)
	}
})

// start checkout event
jQuery(document).on("wpmBeginCheckout", () => {

	try {
		if (!wpmDataLayer?.pixels?.twitter?.loaded) return

		if (wpmDataLayer?.pixels?.twitter?.event_ids?.initiate_checkout) {
			twq("event", wpmDataLayer.pixels.twitter.event_ids.initiate_checkout, {})
			wpm.consoleLog("Twitter Pixel: initiate_checkout event sent", {})
		} else {
			// Legacy event
			twq("track", "InitiateCheckout")
			wpm.consoleLog("Twitter Pixel: initiate_checkout event sent")
		}

	} catch (e) {
		console.error(e)
	}
})

// View search event
jQuery(document).on("pmwEvent:Search", () => {

	try {
		if (!wpmDataLayer?.pixels?.twitter?.loaded) return

		if (wpmDataLayer?.pixels?.twitter?.event_ids?.search) {

			const data = {
				search_string: wpm.getSearchTermFromUrl(),
			}

			twq("event", wpmDataLayer.pixels.twitter.event_ids.search, data)
			wpm.consoleLog("Twitter Pixel: search event sent", data)
		} else {
			// Legacy event
			twq("track", "Search")
			wpm.consoleLog("Twitter Pixel: search event sent")
		}

	} catch (e) {
		console.error(e)
	}
})


// view order received page event
// TODO find out under which circumstances to use different values in content_type
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.twitter?.loaded) return


		if (wpmDataLayer?.pixels?.twitter?.event_ids?.purchase) {
			const data = {
				value        : wpmDataLayer.order.value.marketing,
				currency     : wpmDataLayer.order.currency,
				conversion_id: wpmDataLayer.order.id,
				contents     : wpm.twitterGetOrderContentIdsV2(),
			}
			twq("event", wpmDataLayer.pixels.twitter.event_ids.purchase, data)
			wpm.consoleLog("Twitter Pixel: purchase event sent", data)
		} else {
			// Legacy event
			const data = {
				order_id: wpmDataLayer.order.id,
				// content_type: 'product',
				value      : wpmDataLayer.order.value.marketing,
				currency   : wpmDataLayer.order.currency,
				num_items  : wpmDataLayer.order.quantity,
				content_ids: wpm.twitterGetOrderContentIdsV1(),
			}

			twq("track", "Purchase", data)
			wpm.consoleLog("Twitter Pixel: purchase event sent", data)
		}

	} catch (e) {
		console.error(e)
	}
})

