/**
 * Copyright SweetCode. All rights reserved.
 *
 * Load VWO event listeners
 * */

// Pixel load event listener
jQuery(document).on("pmwLoadPixels", () => {

	if (wpmDataLayer?.pixels?.vwo?.account_id && !wpmDataLayer?.pixels?.vwo?.loaded) {
		if (wpm.consent.canPixelBeFired("statistics", "VWO")) wpm.loadVwoPixel()
	}
})

// view order received page event
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.vwo?.loaded) return

		const data = [
			"track.revenueConversion",
			wpmDataLayer.order.value.total,
		]

		wpm.vwoLoaded().then(() => {
			window.VWO.push(data)
			wpm.consoleLog("VWO Pixel: track.revenueConversion event sent", data)
		})

	} catch (e) {
		console.error(e)
	}
})

