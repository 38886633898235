/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Microsoft Ads event listeners
 *
 * https://help.ads.microsoft.com/#apex/ads/en/56684/2
 * https://help.ads.microsoft.com/#apex/ads/en/60118/-1
 *
 * add_payment_info, add_to_cart, add_to_wishlist, begin_checkout, checkout_progress, exception, generate_lead, login, page_view, purchase, refund, remove_from_cart, screen_view, search, select_content, set_checkout_option, share, sign_up, timing_complete, view_item, view_item_list, view_promotion, view_search_results
 *
 * */

// Pixel load event listener
jQuery(document).on("pmwLoadPixels", () => {

	if (wpmDataLayer?.pixels?.bing?.uet_tag_id && !wpmDataLayer?.pixels?.bing?.loaded) {
		if (wpm.consent.canPixelBeFired("marketing", "Microsoft Ads")) wpm.load_bing_pixel()
	}
})

// https://help.ads.microsoft.com/#apex/ads/en/60118/-1
// add-to-cart event
jQuery(document).on("wpmAddToCart", (event, product) => {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		const data = {
			ecomm_pagetype: "cart",
			ecomm_prodid  : product.dyn_r_ids[wpmDataLayer.pixels.bing.dynamic_remarketing.id_type],
		}

		window.uetq.push("event", "add_to_cart", data)
		wpm.consoleLog("Microsoft Ads: add_to_cart event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// https://help.ads.microsoft.com/#apex/ads/en/60118/-1
// view product event
jQuery(document).on("wpmViewItem", (event, product = null) => {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		let data = {}

		if (product) {
			data.ecomm_pagetype = "product"
			data.ecomm_prodid   = product.dyn_r_ids[wpmDataLayer.pixels.bing.dynamic_remarketing.id_type]
		}

		window.uetq.push("event", "", data)
		wpm.consoleLog("Microsoft Ads: view_item event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// https://help.ads.microsoft.com/#apex/ads/en/60118/-1
// view category event
jQuery(document).on("wpmCategory", () => {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		let data = {
			ecomm_pagetype: "category",
		}

		if (Object.keys(wpmDataLayer?.products || {}).length) {
			data.ecomm_prodid = wpm.getBingDataLayerProducts()
		}

		window.uetq.push("event", "", data)
		wpm.consoleLog("Microsoft Ads: view_category event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// https://help.ads.microsoft.com/#apex/ads/en/60118/-1
// view search event
jQuery(document).on("pmwEvent:Search", () => {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		let data = {
			ecomm_query   : wpm.getSearchTermFromUrl(),
			ecomm_pagetype: "searchresults",
		}

		if (Object.keys(wpmDataLayer?.products || {}).length) {
			data.ecomm_prodid = wpm.getBingDataLayerProducts()
		}

		window.uetq.push("event", "", data)
		wpm.consoleLog("Microsoft Ads: view_search_results event sent", data)

	} catch (e) {
		console.error(e)
	}
})

/**
 * The ecomm_pagetype parameter identifies the type of page the user has visited.
 * Valid options: home, searchresults, category, product, cart, purchase, other.
 * */
jQuery(document).on("wpmEverywhereElse", () => {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		let data = {
			ecomm_pagetype: wpmDataLayer?.shop?.page_type === "front_page" ? "home" : "other",
		}

		if (Object.keys(wpmDataLayer?.products || {}).length) {
			data.ecomm_prodid = wpm.getBingDataLayerProducts()
		}

		window.uetq.push("event", "", data)
		wpm.consoleLog("Microsoft Ads: generic event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// https://help.ads.microsoft.com/#apex/ads/en/60118/-1
// view order received page event
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.bing?.loaded) return

		const data = {
			ecomm_pagetype: "purchase",
			ecomm_prodid  : wpm.bing_purchase_ecomm_prodids(),
			revenue_value : wpmDataLayer.order.value.marketing,
			currency      : wpmDataLayer.order.currency,
			items         : wpm.bing_purchase_items(),
		}

		window.uetq.push("event", "purchase", data)
		wpm.consoleLog("Microsoft Ads: purchase event sent", data)

	} catch (e) {
		console.error(e)
	}
})

